import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Slide from "@mui/material/Slide"
import Toolbar from "@mui/material/Toolbar"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import jas from "assets/logo/logo.png"
import { Link, NavLink } from "react-router-dom"
import { MenuWhatWeDo, MenuWhoWeAre } from "./Menus"

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

export default function Navbar(props) {
  return (
    <Box>
      <HideOnScroll {...props}>
        <AppBar
          elevation={0}
          sx={{
            borderBottom: "1px solid rgba(255,255,255,.15)",
            // background: "transparent",
            py: 0,
            px: { sm: 4, xs: 0 },
          }}
        >
          <Toolbar sx={{ minHeight: "50px !important", flexGrow: 1 }}>
            <Box
              component={Link}
              to="/"
              sx={{
                height: "100%",
                flexGrow: { xs: 1, md: 0 },
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  bgcolor: "white",
                }}
              >
                <img src={jas} width="85px" alt="rgb" />
              </Box>
              {/* <Typography variant="h5">JAS Foundation</Typography> */}
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
              }}
            >
              {/* <NavLink
                to="/who-we-are"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button size="small" sx={{ color: "#fff" }}>
                  WHO WE ARE
                </Button>
              </NavLink> */}

              <MenuWhoWeAre />
              <MenuWhatWeDo />

              <NavLink
                to="/gallery"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button size="small" sx={{ color: "#fff" }}>
                  GALLERY
                </Button>
              </NavLink>

              <NavLink
                to="/contact"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button size="small" sx={{ color: "#fff" }}>
                  CONTACT
                </Button>
              </NavLink>
            </Box>
            <NavLink
              to="about"
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <Button variant="contained" elevation={0} color="warning">
                DONATE NOW
              </Button>
            </NavLink>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Box>
  )
}
