import { Container, Grid } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
// import Company from "./Company"
import Header from "./Header"
import Social from "./Social"

const Footer = () => {
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
      }}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "#f1f6f9",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            py: 10,
          }}
        >
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={5}>
              <Header />
            </Grid>

            <Grid item xs={12} sm={7}>
              <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} sm={6}>
                  {/* <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Company />
                  </Box> */}
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                <Support />
              </Grid> */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "center",
                        sm: "flex-end",
                      },
                      alignItems: "center",
                    }}
                  >
                    <Social />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Typography
        align="center"
        color="secondary"
        sx={{ typography: { xs: "subtitle2", sm: "subtitle1" }, py: 1 }}
      >
        Copyright © {new Date().getFullYear()}. All rights reserved by Jas
        Foundation.
      </Typography>
    </Box>
  )
}

export default Footer
