import { Card, Container, LinearProgress } from '@mui/material'
import Typography from '@mui/material/Typography'

const UnderConstruction = ({ title }) => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        py: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Card>
        <LinearProgress />
        <Typography
          align="center"
          sx={{
            m: 5,
            typography: {
              xs: 'h5',
              sm: 'h4',
            },
          }}
        >
          {title} page is under construction
        </Typography>
        <LinearProgress />
      </Card>
    </Container>
  )
}

export default UnderConstruction
