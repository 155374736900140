import Box from "@mui/material/Box"

const Map = () => {
  return (
    <Box
      sx={{
        display: "block",
        "& iframe": {
          display: "block",
        },
      }}
    >
      <iframe
        title="map"
        width="100%"
        height="400px"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Boira%20Bazar%20Jame%20Mosjid,%20place_of_worship,%20Pogadigha,%20Bangladesh+(Jas%20Foundation)&amp;t=k&z=13&ie=UTF8&iwloc=&output=embed"
        frameBorder="0"
        scrolling="yes"
        marginheight="0"
        marginwidth="0"
      ></iframe>
    </Box>
  )
}

export default Map
