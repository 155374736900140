import About from "pages/About"
import Contact from "pages/Contact"
import Gallery from "pages/Gallery"
import Home from "pages/Home"
import { Route, Routes } from "react-router-dom"

const AppRoutes = () => {
  return (
    <main>
      <Routes>
        <Route index element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="about" element={<About />} />
      </Routes>
    </main>
  )
}

export default AppRoutes
