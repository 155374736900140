import { Home } from "@mui/icons-material"
import EmailIcon from "@mui/icons-material/Email"
import { Divider, Hidden } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import logo from "assets/logo/logo.png"

const Header = () => {
  return (
    <Box
      sx={{
        mt: { xs: -4, sm: 0 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: { xs: "center", sm: "flex-start" },
        flexDirection: "column",
      }}
    >
      <img height={100} src={logo} alt="logo" />
      <Typography
        sx={{
          mt: 1,
          fontWeight: 800,
        }}
        align="left"
        variant="h5"
        gutterBottom
      ></Typography>
      <Typography
        component="a"
        href="mailto:info@jasfoundationbd.org"
        noWrap
        variant="subtitle2"
        sx={{
          mr: 2,
          display: "flex",

          textDecoration: "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <EmailIcon sx={{ fontSize: 20, mr: 0.5, color: "primary.main" }} />
        info@jasfoundationbd.org
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          mr: 2,
          display: "flex",
          textDecoration: "none",
          justifyContent: "center",
          // alignItems: "center",
        }}
      >
        <Home sx={{ fontSize: 20, mr: 0.5, color: "primary.main" }} />
        Address: Vill: Boira Bazar, PO: Pogaldigha, PS: Sarishabari, Dist:
        Jamalpur
      </Typography>
      <Hidden smUp>
        <br />
        <br />
        <Divider
          sx={{
            width: "100%",
            color: "primary.main",
          }}
        />
      </Hidden>
    </Box>
  )
}

export default Header
