import FacebookIcon from "@mui/icons-material/Facebook"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import TwitterIcon from "@mui/icons-material/Twitter"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import { Button, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { Stack } from "@mui/system"

const Social = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: { xs: "center", sm: "flex-start" },
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          mt: 1,
          mb: 2,
          fontWeight: 800,
          textAlign: "center",
        }}
        align="left"
        variant="h5"
        gutterBottom
      >
        Social
      </Typography>

      <Stack direction="row" spacing={2}>
        <Button
          sx={{
            p: 2,
          }}
          color="primary"
          variant="contained"
        >
          <FacebookIcon />
        </Button>
        <Button
          component="a"
          href="#"
          sx={{
            p: 2,
          }}
          color="primary"
          variant="contained"
        >
          <WhatsAppIcon />
        </Button>
      </Stack>
      <Stack
        sx={{
          mt: 2,
        }}
        direction="row"
        spacing={2}
      >
        <Button
          component="a"
          href="#"
          sx={{
            p: 2,
          }}
          color="primary"
          variant="contained"
        >
          <LinkedInIcon />
        </Button>
        <Button
          sx={{
            p: 2,
          }}
          color="primary"
          variant="contained"
        >
          <TwitterIcon />
        </Button>
      </Stack>
    </Box>
  )
}

export default Social
