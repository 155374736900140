import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import PopupState, { bindHover, bindMenu } from "material-ui-popup-state"
import HoverMenu from "material-ui-popup-state/HoverMenu"
import * as React from "react"

export function MenuWhoWeAre() {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button sx={{ color: "#fff" }} {...bindHover(popupState)}>
            WHO WE ARE
          </Button>

          <HoverMenu disableScrollLock={true} {...bindMenu(popupState)}>
            <MenuItem onClick={popupState.close}>Annual Report</MenuItem>
            <MenuItem onClick={popupState.close}>Governing Body</MenuItem>
            <MenuItem onClick={popupState.close}>Executive Body</MenuItem>
          </HoverMenu>
        </React.Fragment>
      )}
    </PopupState>
  )
}

export function MenuWhatWeDo() {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button sx={{ color: "#fff" }} {...bindHover(popupState)}>
            WHAT WE DO
          </Button>

          <HoverMenu disableScrollLock={true} {...bindMenu(popupState)}>
            <MenuItem onClick={popupState.close}>College</MenuItem>
          </HoverMenu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
