import { Box } from "@mui/material"
import ContactForm from "components/Contact/ContactForm"
import Hero from "components/Contact/Hero"
import Map from "components/Contact/Map"

const Contact = () => {
  return (
    <Box>
      <Hero />
      <ContactForm />
      <Map />
    </Box>
  )
}

export default Contact
