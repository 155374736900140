import { CssBaseline } from "@mui/material"
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles"
import Footer from "components/Footer"
import Navbar from "components/Navbar"

import AppRoutes from "Routes"
import ScrollToTop from "utils/ScrollToTop"

const App = () => {
  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#123D44",
      },
      secondary: {
        main: "#ffffff",
      },

      text: {
        primary: "#123D44",
        secondary: "#DDDDDD",
      },
      // background: {
      //   default: "#1d1b19",
      //   paper: "#181715",
      // },
    },
    typography: {
      fontFamily: "Bai Jamjuree",
      // fontWeightLight: 400,
      // fontWeightRegular: 500,
      // fontWeightMedium: 600,
      // fontWeightBold: 700,
      button: {
        textTransform: "none",
      },
    },
  })
  return (
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <CssBaseline />
      <ScrollToTop />
      <Navbar />
      <AppRoutes />
      <Footer />
    </ThemeProvider>
  )
}

export default App
