// import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import { Box, Container, Grid } from "@mui/material"
import Typography from "@mui/material/Typography"
import mission from "assets/images/home/mission.jpg"
// import Animate from "utils/ScrollAnimation"

const Mission = () => {
  return (
    <Box
      sx={{
        bgcolor: "#f1f6f9",
        pb: 5,
      }}
    >
      <Container>
        <Grid container spacing={5} alignItems="stretch">
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              width: "100%",
              "& img": {
                width: "100%",
                height: "500px",
                objectFit: "cover",
                borderRadius: 8,
              },
            }}
          >
            {/* <Animate height="100%" anim="fade-up" anchor="center-bottom"> */}
            <img src={mission} alt="about_left" />
            {/* </Animate> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <Animate anim="fade-up" anchor="center-bottom"> */}
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: 2,
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Box>
                <Typography
                  align="left"
                  sx={{
                    typography: {
                      xs: "subtitle1",
                      sm: "h6",
                    },
                    fontWeight: "bold !important",
                  }}
                  color="primary.main"
                >
                  Our Mission
                </Typography>

                <Typography align="justify" variant="subtitle2" gutterBottom>
                  Jas Foundation serves to combat poverty, illiteracy, and
                  social injustice by empowering individuals and communities
                  through scalable programs in education, health, and economic
                  development
                </Typography>
              </Box>
              <Box>
                <Typography
                  align="left"
                  sx={{
                    typography: {
                      xs: "subtitle1",
                      sm: "h6",
                    },
                    fontWeight: "bold !important",
                  }}
                  color="primary.main"
                >
                  Our Vision
                </Typography>

                <Typography align="justify" variant="subtitle2" gutterBottom>
                  To create a world where dignity and hope flourish for all,
                  especially the marginalized. We strive to eliminate
                  exploitation and discrimination, fostering justice and
                  inclusion. Through collaboration and diversity, we aim to
                  build a fairer, more equitable society.
                </Typography>
              </Box>
              <Box>
                <Typography
                  align="left"
                  sx={{
                    typography: {
                      xs: "subtitle1",
                      sm: "h6",
                    },
                    fontWeight: "bold !important",
                  }}
                  color="primary.main"
                >
                  Our Values
                </Typography>

                <Typography align="justify" variant="subtitle2" gutterBottom>
                  <ul>
                    <li>Empowerment</li>
                    <li>Inclusivity</li>
                    <li>Integrity</li>
                    <li>Justice</li>
                    <li>Sustainability</li>
                  </ul>
                </Typography>
              </Box>
            </Box>
            {/* </Animate> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Mission
