import { Box, Button, Container } from "@mui/material"
import Typography from "@mui/material/Typography"

const WhoAreWe = () => {
  return (
    <Container
      sx={{
        my: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mb: 10,
      }}
    >
      <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
        WHO WE ARE
      </Typography>
      <Typography variant="subtitle1" align="center" color="slategrey">
        Jas Foundation, an international development organisation founded in
        Bangladesh in 2024 that has been dedicated to initiatives spanning
        health, education, agriculture, aquaculture, livestock, and more.
      </Typography>
      <Button
        size="small"
        sx={{
          mt: 2,
          px: 5,
        }}
        variant="outlined"
      >
        LEARN MORE
      </Button>
      <Box
        sx={{
          mt: 5,
          display: "flex",

          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Button variant="contained" size="large">
          Recognition
        </Button>
        <Button variant="contained" size="large">
          Vision, Mission and Values
        </Button>
        <Button variant="contained" size="large">
          Our DNA
        </Button>
        <Button variant="contained" size="large">
          College
        </Button>
      </Box>
    </Container>
  )
}

export default WhoAreWe
