import { Card, Container, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

const Subscribe = () => {
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        py: 5,
      }}
    >
      <Container>
        <Typography align="center" variant="h3" color="secondary" gutterBottom>
          Subscribe
        </Typography>
        <Card
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "secondary.main",
            color: "primary.main",
          }}
        >
          <TextField
            fullWidth
            label="Enter your email address"
            variant="outlined"
            sx={{
              color: "primary.main",
            }}
          />
        </Card>
      </Container>
    </Box>
  )
}

export default Subscribe
