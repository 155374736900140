import { Box } from "@mui/material"
import Hero from "components/Home/Hero"
import Mission from "components/Home/Mission"
import Subscribe from "components/Home/Subscribe"
import WhoAreWe from "components/Home/WhoAreWe"

const Home = () => {
  return (
    <Box>
      <Hero />
      <WhoAreWe />
      <Mission />
      <Subscribe />
    </Box>
  )
}

export default Home
