import { Button } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import hero from "assets/images/home/hero.jpg"

const Hero = () => {
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
          url(${hero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // backgroundAttachment: {
        //   xs: "scroll",
        //   sm: "fixed",
        // },
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: "white",
          textAlign: "center",
          fontWeight: "600",
        }}
      >
        Education, Women Empowerment, <br /> Equality
      </Typography>
      <Button
        size="large"
        sx={{
          mt: 5,
          px: 5,
          py: 1.2,
        }}
        variant="contained"
      >
        LEARN MORE
      </Button>
    </Box>
  )
}

export default Hero
